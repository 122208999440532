const baseUrl = process.env.VUE_APP_BASE_API_URL;
const storageUrl = process.env.VUE_APP_FILE_STORAGE_API_URL;
const loanStorageUrl = process.env.VUE_APP_LOAN_FILE_STORAGE_API_URL;
const barStorageUrl = process.env.VUE_APP_BAR_FILE_STORAGE_API_URL;
const authUrl = process.env.VUE_APP_OIDC_AUTHORITY;
const loanScheduleUrl = process.env.VUE_APP_LOAN_SCHEDULE_API_URL;
const troubledBorrowerUrl = process.env.VUE_APP_TROUBLED_BORROWER_API_URL;
const bodUrl = process.env.VUE_APP_BOD_API_URL;
const kpiUrl = process.env.VUE_APP_KPI_API_URL;
const reportsUrl = process.env.VUE_APP_LOAN_REPORTS_API_URL;
const loanUrl = process.env.VUE_APP_LOAN_BASE_URL;
const barUrl = process.env.VUE_APP_BAR_API_URL;
const tranchesUrl = process.env.VUE_APP_TRANCHES_API_URL;
const rexUrl = process.env.VUE_APP_REX_API_URL;
const hrUrl = process.env.VUE_APP_HR_API_URL;
const hrKpiUrl = process.env.VUE_APP_HR_KPI_API_URL;

export const urlTemplateParts = {
	id: "{id}",
	subId: "{subId}",
	thirdId: "{thirdId}",
	fourthId: "{fourthId}",
	fifthId: "{fifthId}"
};

const urls = {
	apps: {
		getApps: `${baseUrl}/apps/own`
	},
	applications: {
		masterAccountAccess: {
			createLegalEntityAccountApplication: `${baseUrl}/master-application/legal-entity`,
			createLegalEntityAccountFromEmployeeApplication: `${baseUrl}/master-application/legal-entity/employee`,
			createSignedLegalEntityAccountApplication: `${baseUrl}/master-application/legal-entity-eds`,
			createLegalPersonAccountApplication: `${baseUrl}/master-application/entrepreneur`,
			createLegalPersonAccountFromEmployeeApplication: `${baseUrl}/master-application/entrepreneur/employee`,
			getLegalEntityAccountApplicationId: `${baseUrl}/master-application/legal-entity/exists`,
			getLegalPersonAccountApplicationId: `${baseUrl}/master-application/entrepreneur/exists`,
			updateStatus: `${baseUrl}/master-application/${urlTemplateParts.id}/status`,
			getMasterAccountAccessApplications: `${baseUrl}/master-application`,
			getApplicationDetails: `${baseUrl}/master-application/${urlTemplateParts.id}`
		},
		employeeJoin: {
			createLegalEntityEmployeeJoinApplication: `${baseUrl}/counterparty-application/legal-entity`,
			createLegalPersonEmployeeJoinApplication: `${baseUrl}/counterparty-application/entrepreneur`,
			getEmployeeJoinApplications: `${baseUrl}/counterparty-application`,
			getOwnEmployeeJoinApplications: `${baseUrl}/own/counterparty-application`,
			getApplicationDetails: `${baseUrl}/counterparty-application/${urlTemplateParts.id}`,
			getOwnApplicationDetails: `${baseUrl}/own/counterparty-application/${urlTemplateParts.id}`,
			updateStatus: `${baseUrl}/counterparty-application/${urlTemplateParts.id}/status`,
			updateOwnStatus: `${baseUrl}/own/counterparty-application/${urlTemplateParts.id}/status`
		}
	},
	masterAccount: {
		getMasterAccountId: `${baseUrl}/master-account/id`
	},
	fns: {
		getEgrulLegalEntity: `${baseUrl}/fns/egrul/legal-entity`,
		getEgripLegalPersonByInn: `${baseUrl}/fns/egrip/entrepreneur/inn`,
		upToDate: {
			getLegalPerson: `${baseUrl}/fns/up-to-date/entrepreneur`,
			enableLegalPerson: `${baseUrl}/fns/up-to-date/entrepreneur`,
			disableLegalPerson: `${baseUrl}/fns/up-to-date/entrepreneur`,
			enableLegalEntity: `${baseUrl}/fns/up-to-date/legal-entity`,
			disableLegalEntity: `${baseUrl}/fns/up-to-date/legal-entity`,
			getLegalEntity: `${baseUrl}/fns/up-to-date/legal-entity`,
			updateLegalEntity: `${baseUrl}/fns/up-to-date/legal-entity/force`,
			updateLegalPerson: `${baseUrl}/fns/up-to-date/entrepreneur/force`
		}
	},
	storage: {
		file: {
			createTemper: `${storageUrl}/file/temp`,
			createTemperSigned: `${storageUrl}/file/temp/signed`,
			download: `${storageUrl}/file/data/${urlTemplateParts.id}`,
			getMeta: `${storageUrl}/file/meta/${urlTemplateParts.id}`
		}
	},
	loanStorage: {
		file: {
			createTemper: `${loanStorageUrl}/file/temp`,
			createTemperSigned: `${loanStorageUrl}/file/temp/signed`,
			tempDownload: `${loanStorageUrl}/file/temp/data/${urlTemplateParts.id}`,
			download: `${loanStorageUrl}/file/data/${urlTemplateParts.id}`,
			getMeta: `${loanStorageUrl}/file/meta/${urlTemplateParts.id}`
		},
		cryptopro: {
			getSignerCertificates: `${loanStorageUrl}/jcp/signature/signer`
		}
	},
	roles: {
		officeEmployee: {
			getAllRoles: `${baseUrl}/role/office-employee`,
			getOwnActiveRoles: `${baseUrl}/role/office-employee/own-roles`,
			getActiveRolesById: `${baseUrl}/role/office-employee/${urlTemplateParts.id}`,
			saveActiveRoles: `${baseUrl}/role/office-employee/${urlTemplateParts.id}`
		}
	},
	office: {
		getOffices: `${baseUrl}/staff/office`,
		getOffice: `${baseUrl}/staff/office/${urlTemplateParts.id}`,
		updateOffice: `${baseUrl}/staff/office/${urlTemplateParts.id}`,
		createOffice: `${baseUrl}/staff/office`,
		getRoles: `${baseUrl}/staff/role`,
		employee: {
			getEmployeeProfile: `${baseUrl}/staff/office/${urlTemplateParts.id}/employee/${urlTemplateParts.subId}/profile`,
			get: `${baseUrl}/staff/office/${urlTemplateParts.id}/employee/${urlTemplateParts.subId}`,
			getById: `${baseUrl}/staff/employee/${urlTemplateParts.id}`,
			getItems: `${baseUrl}/staff/office/${urlTemplateParts.id}/employee`,
			update: `${baseUrl}/staff/office/${urlTemplateParts.id}/employee/${urlTemplateParts.subId}`,
			create: `${baseUrl}/staff/office/${urlTemplateParts.id}/employee`,
			activate: `${baseUrl}/staff/office/${urlTemplateParts.id}/employee/${urlTemplateParts.subId}/activate`,
			deactivate: `${baseUrl}/staff/office/${urlTemplateParts.id}/employee/${urlTemplateParts.subId}/deactivate`
		}
	},
	counterparty: {
		getCounterparties: `${baseUrl}/counterparty`,
		getCounterparty: `${baseUrl}/counterparty/${urlTemplateParts.id}`,
		getOwnCounterparty: `${baseUrl}/own/counterparty`,
		getCounterpartyProfile: `${baseUrl}/counterparty/${urlTemplateParts.id}/profile`,
		employee: {
			getEmployeeProfile: `${baseUrl}/counterparty/${urlTemplateParts.id}/employee/${urlTemplateParts.subId}/profile`,
			getOwnEmployeeProfile: `${baseUrl}/own/counterparty/employee/${urlTemplateParts.subId}/profile`,
			get: `${baseUrl}/counterparty/${urlTemplateParts.id}/employee/${urlTemplateParts.subId}`,
			getOwn: `${baseUrl}/own/counterparty/employee/${urlTemplateParts.subId}`,
			getItems: `${baseUrl}/counterparty/${urlTemplateParts.id}/employee`,
			getOwnItems: `${baseUrl}/own/counterparty/employee`,
			activate: `${baseUrl}/counterparty/${urlTemplateParts.id}/employee/${urlTemplateParts.subId}/activate`,
			activateOwn: `${baseUrl}/own/counterparty/employee/own/${urlTemplateParts.subId}/activate`,
			deactivate: `${baseUrl}/counterparty/${urlTemplateParts.id}/employee/${urlTemplateParts.subId}/deactivate`,
			deactivateOwn: `${baseUrl}/own/counterparty/employee/own/${urlTemplateParts.subId}/deactivate`,
			trust: {
				getItems: `${baseUrl}/counterparty/${urlTemplateParts.id}/employee/${urlTemplateParts.subId}/trust`,
				getOwnItems: `${baseUrl}/own/counterparty/employee/${urlTemplateParts.subId}/trust`,
				get: `${baseUrl}/counterparty/${urlTemplateParts.id}/employee/${urlTemplateParts.subId}/trust/${urlTemplateParts.thirdId}`,
				getOwn: `${baseUrl}/own/counterparty/employee/${urlTemplateParts.subId}/trust/${urlTemplateParts.thirdId}`,
				create: `${baseUrl}/counterparty/${urlTemplateParts.id}/employee/${urlTemplateParts.subId}/trust`,
				accept: `${baseUrl}/counterparty/${urlTemplateParts.id}/employee/${urlTemplateParts.subId}/trust/${urlTemplateParts.thirdId}/accept`,
				cancel: `${baseUrl}/counterparty/${urlTemplateParts.id}/employee/${urlTemplateParts.subId}/trust/${urlTemplateParts.thirdId}/cancel`,
				decline: `${baseUrl}/counterparty/${urlTemplateParts.id}/employee/${urlTemplateParts.subId}/trust/${urlTemplateParts.thirdId}/decline`,
				getActioner: `${baseUrl}/trust/${urlTemplateParts.id}/actioner`,
				getOwnActioner: `${baseUrl}/own/counterparty/employee/${urlTemplateParts.id}/trust/${urlTemplateParts.subId}/actioner`
			},
			snils: {
				create: `${baseUrl}/counterparty/${urlTemplateParts.id}/employee/${urlTemplateParts.subId}/snils`,
				createOwn: `${baseUrl}/own/counterparty/employee/${urlTemplateParts.subId}/snils`,
				update: `${baseUrl}/counterparty/${urlTemplateParts.id}/employee/${urlTemplateParts.subId}/snils`,
				updateOwn: `${baseUrl}/own/counterparty/employee/${urlTemplateParts.subId}/snils`,
				get: `${baseUrl}/counterparty/${urlTemplateParts.id}/employee/${urlTemplateParts.subId}/snils`,
				getOwn: `${baseUrl}/own/counterparty/employee/${urlTemplateParts.subId}/snils`
			}
		},
		legalPerson: {
			get: `${baseUrl}/counterparty/entrepreneur/${urlTemplateParts.id}`,
			create: `${baseUrl}/counterparty/entrepreneur`,
			getSuggestion: `${baseUrl}/counterparty/entrepreneur/suggestion/complete`,
			checkEmployeeExists: `${baseUrl}/counterparty/entrepreneur/check-employee-exists`,
			update: `${baseUrl}/counterparty/entrepreneur/${urlTemplateParts.id}`,
			checkIsExists: `${baseUrl}/counterparty/entrepreneur/exists`,
			search: `${baseUrl}/counterparty/entrepreneur/search`,
			profile: {
				check: `${baseUrl}/counterparty/entrepreneur/profile-check`,
				get: `${baseUrl}/counterparty/entrepreneur/${urlTemplateParts.id}/profile`,
				create: `${baseUrl}/counterparty/entrepreneur/${urlTemplateParts.id}/profile`,
				update: `${baseUrl}/counterparty/entrepreneur/${urlTemplateParts.id}/profile`
			},
			employee: {
				get: `${baseUrl}/counterparty/entrepreneur/${urlTemplateParts.id}/employee/${urlTemplateParts.subId}`,
				getItems: `${baseUrl}/counterparty/entrepreneur/${urlTemplateParts.id}/employee`,
				update: `${baseUrl}/counterparty/entrepreneur/${urlTemplateParts.id}/employee/${urlTemplateParts.subId}`,
				updateOwn: `${baseUrl}/own/counterparty/entrepreneur/employee/${urlTemplateParts.id}`,
				create: `${baseUrl}/counterparty/entrepreneur/${urlTemplateParts.id}/employee`,
				createOwn: `${baseUrl}/own/counterparty/entrepreneur/employee`
			}
		},
		physicalPerson: {
			get: `${baseUrl}/counterparty/individual/${urlTemplateParts.id}`,
			create: `${baseUrl}/counterparty/individual`,
			update: `${baseUrl}/counterparty/individual/${urlTemplateParts.id}`,
			checkIsExists: `${baseUrl}/counterparty/individual/exists`,
			search: `${baseUrl}/counterparty/individual/search`
		},
		foreignOrganization: {
			get: `${baseUrl}/counterparty/foreign-organization/${urlTemplateParts.id}`,
			create: `${baseUrl}/counterparty/foreign-organization`,
			checkIsExists: `${baseUrl}/counterparty/foreign-organization/exists`,
			search: `${baseUrl}/counterparty/foreign-organization/search`,
			update: `${baseUrl}/counterparty/foreign-organization/${urlTemplateParts.id}`,
			employee: {
				get: `${baseUrl}/counterparty/foreign-organization/${urlTemplateParts.id}`,
				getItems: `${baseUrl}/counterparty/foreign-organization/${urlTemplateParts.id}/employee`,
				update: `${baseUrl}/counterparty/foreign-organization/employee/${urlTemplateParts.id}`,
				create: `${baseUrl}/counterparty/foreign-organization/${urlTemplateParts.id}/employee`
			}
		},
		legalEntity: {
			get: `${baseUrl}/counterparty/legal-entity/${urlTemplateParts.id}`,
			checkIsExists: `${baseUrl}/counterparty/legal-entity/exists`,
			checkEmployeeExists: `${baseUrl}/counterparty/legal-entity/check-employee-exists`,
			getByIdentifiers: `${baseUrl}/counterparty/legal-entity/search`,
			create: `${baseUrl}/counterparty/legal-entity`,
			update: `${baseUrl}/counterparty/legal-entity/${urlTemplateParts.id}`,
			getSuggestion: `${baseUrl}/counterparty/legal-entity/suggestion/complete`,
			profile: {
				check: `${baseUrl}/counterparty/legal-entity/profile-check`,
				get: `${baseUrl}/counterparty/legal-entity/${urlTemplateParts.id}/profile`,
				create: `${baseUrl}/counterparty/legal-entity/${urlTemplateParts.id}/profile`,
				update: `${baseUrl}/counterparty/legal-entity/${urlTemplateParts.id}/profile`
			},
			head: {
				get: `${baseUrl}/counterparty/legal-entity/${urlTemplateParts.id}/head`,
				getOwn: `${baseUrl}/own/counterparty/legal-entity/head`,
				snils: {
					update: `${baseUrl}/counterparty/legal-entity/${urlTemplateParts.id}/head/${urlTemplateParts.subId}/snils`,
					updateOwn: `${baseUrl}/own/counterparty/legal-entity/head/${urlTemplateParts.subId}/snils`,
					get: `${baseUrl}/counterparty/legal-entity/${urlTemplateParts.id}/head/${urlTemplateParts.subId}/snils`,
					getOwn: `${baseUrl}/own/counterparty/legal-entity/head/${urlTemplateParts.subId}/snils`,
					create: `${baseUrl}/counterparty/legal-entity/${urlTemplateParts.id}/head/${urlTemplateParts.subId}/snils`,
					createOwn: `${baseUrl}/own/counterparty/legal-entity/head/${urlTemplateParts.subId}/snils`
				}
			},
			employee: {
				get: `${baseUrl}/counterparty/legal-entity/${urlTemplateParts.id}/employee/${urlTemplateParts.subId}`,
				getItems: `${baseUrl}/counterparty/legal-entity/${urlTemplateParts.id}/employee`,
				update: `${baseUrl}/counterparty/legal-entity/${urlTemplateParts.id}/employee/${urlTemplateParts.subId}`,
				updateOwn: `${baseUrl}/own/counterparty/legal-entity/employee/${urlTemplateParts.subId}`,
				create: `${baseUrl}/counterparty/legal-entity/${urlTemplateParts.id}/employee`,
				createOwn: `${baseUrl}/own/counterparty/legal-entity/employee`
			}
		}
	},
	employeeInvitation: {
		get: `${baseUrl}/employee/join`
	},
	cryptopro: {
		getSignerCertificates: `${baseUrl}/jcp/signature/signer`
	},
	snils: {
		get: `${baseUrl}/snils/${urlTemplateParts.id}`,
		recognition: {
			recognize: `${baseUrl}/snils/recognition/${urlTemplateParts.id}`
		}
	},
	account: {
		getAccounts: `${baseUrl}/account`,
		getAccount: `${baseUrl}/account/${urlTemplateParts.id}`,
		getAccountByEmail: `${baseUrl}/account/getbyemail`,
		checkEmail: `${baseUrl}/account/checkemail`,
		checkEmailById: `${baseUrl}/account/${urlTemplateParts.id}/checkemail`,
		getProfiles: `${baseUrl}/account/profile`,
		getProfilesById: `${baseUrl}/account/${urlTemplateParts.id}/profile`,
		updateStatus: `${baseUrl}/account/${urlTemplateParts.id}/active`,
		updateEmail: `${baseUrl}/account/${urlTemplateParts.id}/email`,
		generateAndSendCredentials: `${baseUrl}/account/${urlTemplateParts.id}/reset`,
		updatePassword: `${baseUrl}/account/${urlTemplateParts.id}/password`,
		resetPassword: `${baseUrl}/account/${urlTemplateParts.id}/reset-password`
	},
	profile: {
		getUnassigned: `${baseUrl}/profile/${urlTemplateParts.id}`,
		assign: `${baseUrl}/profile/${urlTemplateParts.id}/assign`,
		activate: `${baseUrl}/account/${urlTemplateParts.id}/profile/${urlTemplateParts.subId}/activate`,
		deactivate: `${baseUrl}/account/${urlTemplateParts.id}/profile/${urlTemplateParts.subId}/deactivate`,
		toggleSignatureRequired: `${baseUrl}/profile/${urlTemplateParts.id}/toggle-signature-required`
	},
	auth: {
		confirmEmail: `${authUrl}/auth/confirm-email`,
		confirmPhone: `${authUrl}/auth/confirm-profile-phone`,
		getCode: `${authUrl}/auth/request-code`,
		getPhoneCode: `${authUrl}/auth/request-profile-phone-confirmation-code`,
		requestPhoneConfirmation: `${authUrl}/auth/profile-phone-confirmation-required`,
		signInWithProfile: `${authUrl}/connect/sign-in-with-profile`
	},
	permissions: {
		get: `${baseUrl}/permissions/own`
	},
	loan: {
		user: {
			getCurrentUser: `${loanScheduleUrl}/users/me`
		},
		permissions: {
			get: `${loanScheduleUrl}/permissions`
		},
		dictionaries: {
			getPeriods: `${loanScheduleUrl}/dictionaries/periods`,
			getAgreementStatuses: `${loanScheduleUrl}/dictionaries/agreement-statuses`,
			getPaymentExpenses: `${loanScheduleUrl}/dictionaries/payment-expenses`,
			getTitleDocuments: `${loanScheduleUrl}/dictionaries/title-documents`,
			getChangeReasons: `${loanScheduleUrl}/dictionaries/change-reasons`,
			getProjectQuarters: `${loanScheduleUrl}/dictionaries/quarters/${urlTemplateParts.id}`,
			getQuarters: `${loanScheduleUrl}/dictionaries/quarters`,
			getAllFinanceSources: `${loanScheduleUrl}/dictionaries/finance-sources`,
			getProjectFinanceSources: `${loanScheduleUrl}/dictionaries/finance-sources/${urlTemplateParts.id}`,
			getPenaltyTypes: `${loanScheduleUrl}/dictionaries/penalty-types`,
			getAccrualTypes: `${loanScheduleUrl}/dictionaries/accrual-types`,
			getBorrowers: `${loanScheduleUrl}/dictionaries/borrowers`,
			getPaymentExpenseTypes: `${loanScheduleUrl}/dictionaries/payment-expense-types`,
			getTreasuryStatementOperationTypes: `${loanScheduleUrl}/dictionaries/treasury-statement-operation-types`,
			getProjectStatus: `${loanScheduleUrl}/dictionaries/projects/${urlTemplateParts.id}/status`,
			getAssignmentAgreements: `${loanScheduleUrl}/dictionaries/assignment-agreements`,
			getAssigneeCompanies: `${loanScheduleUrl}/dictionaries/assignee-companies`
		},
		loanSchedule: {
			agreement: {
				get: `${loanScheduleUrl}/agreements/${urlTemplateParts.id}`
			},
			versions: {
				getVersionHeaders: `${loanScheduleUrl}/version-headers/${urlTemplateParts.id}`,
				getVersion: `${loanScheduleUrl}/versions/${urlTemplateParts.id}`,
				createFirstDraft: `${loanScheduleUrl}/versions`,
				createDraft: `${loanScheduleUrl}/versions/${urlTemplateParts.id}/draft`,
				updateDraft: `${loanScheduleUrl}/versions/${urlTemplateParts.id}`,
				activateVersion: `${loanScheduleUrl}/versions/${urlTemplateParts.id}/active`,
				deleteDraft: `${loanScheduleUrl}/versions/${urlTemplateParts.id}`
			},
			schedule: {
				get: `${loanScheduleUrl}/versions/${urlTemplateParts.id}/schedule`,
				create: `${loanScheduleUrl}/versions/${urlTemplateParts.id}/schedule`,
				update: `${loanScheduleUrl}/versions/${urlTemplateParts.id}/schedule`,
				delete: `${loanScheduleUrl}/versions/${urlTemplateParts.id}/schedule/${urlTemplateParts.subId}`
			},
			tranches: {
				get: `${loanScheduleUrl}/versions/${urlTemplateParts.id}/tranches`,
				create: `${loanScheduleUrl}/versions/${urlTemplateParts.id}/tranches`,
				update: `${loanScheduleUrl}/versions/${urlTemplateParts.id}/tranches`,
				delete: `${loanScheduleUrl}/versions/${urlTemplateParts.id}/tranches/${urlTemplateParts.subId}`
			},
			payments: {
				get: `${loanScheduleUrl}/payments/report/${urlTemplateParts.id}`
			},
			overdues: {
				get: `${loanScheduleUrl}/versions/${urlTemplateParts.id}/overdues`,
				create: `${loanScheduleUrl}/versions/${urlTemplateParts.id}/overdues`,
				update: `${loanScheduleUrl}/versions/${urlTemplateParts.id}/overdues`,
				delete: `${loanScheduleUrl}/versions/${urlTemplateParts.id}/overdues/${urlTemplateParts.subId}`
			}
		},
		loanInterest: {
			agreementsAccruedInterest: {
				get: `${loanScheduleUrl}/agreements-accrued-interest/search`,
				accrueInterest: `${loanScheduleUrl}/agreements-accrued-interest/accrue-interest`,
				accounting: `${loanScheduleUrl}/agreements-accrued-interest/accounting`,
				approveAccounting: `${loanScheduleUrl}/agreements-accrued-interest/approve-accounting`
			},
			agreementsPayments: {
				getPenaltyInterest: `${loanScheduleUrl}/penalty/penalty-interest`,
				getPenaltyMainDebt: `${loanScheduleUrl}/penalty/penalty-maindebt`,
				getOverdueInterest: `${loanScheduleUrl}/penalty/overdue-interest`,
				getOverdueMainDebt: `${loanScheduleUrl}/penalty/overdue-maindebt`
			},
			accruedInterest: {
				get: `${loanScheduleUrl}/accrued-interest/search/${urlTemplateParts.id}`,
				add: `${loanScheduleUrl}/accrued-interest/add`,
				update: `${loanScheduleUrl}/accrued-interest/${urlTemplateParts.id}`,
				massUpdate: `${loanScheduleUrl}/accrued-interest/mass-update`,
				extraRate: `${loanScheduleUrl}/accrued-interest/extra-rate`,
				delete: `${loanScheduleUrl}/accrued-interest/${urlTemplateParts.id}`
			},
			overdueInterest: {
				get: `${loanScheduleUrl}/overdue-interest/${urlTemplateParts.id}`,
				create: `${loanScheduleUrl}/overdue-interest/${urlTemplateParts.id}/item`,
				update: `${loanScheduleUrl}/overdue-interest/${urlTemplateParts.id}`,
				delete: `${loanScheduleUrl}/overdue-interest/${urlTemplateParts.id}/item/${urlTemplateParts.subId}`
			},
			penaltyInterest: {
				get: `${loanScheduleUrl}/penalty-interest/${urlTemplateParts.id}`,
				create: `${loanScheduleUrl}/penalty-interest/${urlTemplateParts.id}/item`,
				update: `${loanScheduleUrl}/penalty-interest/${urlTemplateParts.id}`,
				delete: `${loanScheduleUrl}/penalty-interest/${urlTemplateParts.id}/item/${urlTemplateParts.subId}`
			},
			upcomingPayments: {
				getUpcomingPayments: `${loanScheduleUrl}/payments/nearest/${urlTemplateParts.id}`,
				getUpcomingPaymentsInterestDetails: `${loanScheduleUrl}/accrued-interest/details/${urlTemplateParts.id}`,
				getPaymentOrderFile: `${loanScheduleUrl}/payment-orders/generate/${urlTemplateParts.id}/financing-source/${urlTemplateParts.subId}/type/${urlTemplateParts.thirdId}`
			}
		},
		paymentsProcessing: {
			cashFlow: {
				get: `${loanScheduleUrl}/payments/search/${urlTemplateParts.id}`,
				create: `${loanScheduleUrl}/payments`,
				update: `${loanScheduleUrl}/payments/${urlTemplateParts.id}`,
				delete: `${loanScheduleUrl}/payments/${urlTemplateParts.id}`
			},
			treasuryStatement: {
				createPayment: `${loanScheduleUrl}/treasury-statement/${urlTemplateParts.id}/records/${urlTemplateParts.subId}/payments`,
				updatePayment: `${loanScheduleUrl}/treasury-statement/${urlTemplateParts.id}/records/${urlTemplateParts.subId}/payments/${urlTemplateParts.thirdId}`,
				deletePayment: `${loanScheduleUrl}/treasury-statement/${urlTemplateParts.id}/records/${urlTemplateParts.subId}/payments/${urlTemplateParts.thirdId}`,
				uploadStatement: `${loanScheduleUrl}/treasury-statement/file`,
				handleStatement: `${loanScheduleUrl}/treasury-statement/${urlTemplateParts.id}/handle`,
				updateRecordStatus: `${loanScheduleUrl}/treasury-statement/${urlTemplateParts.id}/records/${urlTemplateParts.subId}/status`,
				updateRecordProject: `${loanScheduleUrl}/treasury-statement/${urlTemplateParts.id}/records/${urlTemplateParts.subId}/project`
			}
		},
		overdueOrder: {
			add: `${loanScheduleUrl}/agreement-overdues/{mode}/`,
			get: `${loanScheduleUrl}/agreement-overdues/{mode}/{orderNumber}`,
			report: `${loanScheduleUrl}/agreement-overdues/{mode}/{orderNumber}/report`,
			submit: `${loanScheduleUrl}/agreement-overdues/{mode}/${urlTemplateParts.id}`
		},
		assignmentCompensation: {
			getProjects: `${loanScheduleUrl}/assignment-compensation/projects`,
			addInterest: `${loanScheduleUrl}/assignment-compensation/${urlTemplateParts.id}`,
			calculateAssignmentCompensation: `${loanScheduleUrl}/assignment-compensation/calculate`,
			createOrderForSubmissionToAccounting: `${loanScheduleUrl}/assignment-compensation/order`,
			getAccountingReport: `${loanScheduleUrl}/assignment-compensation/order/${urlTemplateParts.id}/report`,
			sendDocumentToAccounting: `${loanScheduleUrl}/assignment-compensation/order/${urlTemplateParts.id}/approve`
		}
	},
	troubledBorrower: {
		permissions: {
			get: `${troubledBorrowerUrl}/permissions/${urlTemplateParts.id}`
		},
		dictionaries: {
			getOrganizations: `${troubledBorrowerUrl}/dictionary/organization`,
			getEventTypes: `${troubledBorrowerUrl}/dictionary/event-type`,
			getRequirementTypes: `${troubledBorrowerUrl}/dictionary/requirement-type`,
			getAssessmentTypes: `${troubledBorrowerUrl}/dictionary/assessment-type`,
			getSecurityKindTypes: `${troubledBorrowerUrl}/dictionary/security-kind-type`,
			getProjectManagers: `${troubledBorrowerUrl}/dictionary/users/project-manager`,
			getProblemDebtDepartment: `${troubledBorrowerUrl}/dictionary/users/problem-debt-department`,
			getDebtorStatusTypes: `${troubledBorrowerUrl}/dictionary/debtor-status-type`,
			getJudicialDecisionTypes: `${troubledBorrowerUrl}/dictionary/judicial-decision-type`,
			getRefundStatusTypes: `${troubledBorrowerUrl}/dictionary/refund-status-type`,
			getProjectRoleTypes: `${troubledBorrowerUrl}/dictionary/project-role-type`,
			getRefundSourceTypes: `${troubledBorrowerUrl}/dictionary/refund-source-type`,
			getCostTypes: `${troubledBorrowerUrl}/dictionary/cost-type`
		},
		versions: {
			getVersionHeaders: `${troubledBorrowerUrl}/version-header/${urlTemplateParts.id}`,
			getVersions: `${troubledBorrowerUrl}/version/${urlTemplateParts.id}`,
			createVersion: `${troubledBorrowerUrl}/version/${urlTemplateParts.id}`,
			updateVersion: `${troubledBorrowerUrl}/version/${urlTemplateParts.id}`,
			getCompany: `${troubledBorrowerUrl}/company/${urlTemplateParts.id}`,
			getProject: `${troubledBorrowerUrl}/project/${urlTemplateParts.id}`,
			getRefundSourceHistory: `${troubledBorrowerUrl}/version/${urlTemplateParts.id}/refundSourceHistory`,
			deleteRefundSourceHistory: `${troubledBorrowerUrl}/version/refundSourceHistory/${urlTemplateParts.id}`,
			incomePredictionHistory: `${troubledBorrowerUrl}/version/${urlTemplateParts.id}/incomePredictionHistory`,
			refundStatusTypeHistory: `${troubledBorrowerUrl}/version/${urlTemplateParts.id}/refundStatusTypeHistory`
		},
		securityAgreements: {
			getSecurityAgreements: `${troubledBorrowerUrl}/security-agreement/${urlTemplateParts.id}`,
			updateSecurityAgreements: `${troubledBorrowerUrl}/security-agreement`
		},
		productionStatus: {
			getDebtors: `${troubledBorrowerUrl}/version/${urlTemplateParts.id}/debtor`,
			createDebtor: `${troubledBorrowerUrl}/version/${urlTemplateParts.id}/debtor`,
			updateDebtor: `${troubledBorrowerUrl}/version/${urlTemplateParts.id}/debtor/${urlTemplateParts.subId}`,
			deleteDebtor: `${troubledBorrowerUrl}/version/${urlTemplateParts.id}/debtor/${urlTemplateParts.subId}`,
			getDebtorStatusHistory: `${troubledBorrowerUrl}/version/${urlTemplateParts.id}/debtor/${urlTemplateParts.subId}/status`,
			updateDebtorStatus: `${troubledBorrowerUrl}/version/${urlTemplateParts.id}/debtor/${urlTemplateParts.subId}/status`,
			deleteDebtorStatus: `${troubledBorrowerUrl}/version/${urlTemplateParts.id}/debtor/${urlTemplateParts.subId}/status/${urlTemplateParts.thirdId}`,
			updateDebtorStatusComment: `${troubledBorrowerUrl}/version/${urlTemplateParts.id}/debtor/${urlTemplateParts.subId}/current-status-comment`,
			createEvent: `${troubledBorrowerUrl}/version/${urlTemplateParts.id}/debtor/${urlTemplateParts.subId}/event`,
			updateEvent: `${troubledBorrowerUrl}/version/${urlTemplateParts.id}/debtor/${urlTemplateParts.subId}/event`,
			deleteEvent: `${troubledBorrowerUrl}/version/${urlTemplateParts.id}/debtor/${urlTemplateParts.subId}/event/${urlTemplateParts.thirdId}`,
			createRequirement: `${troubledBorrowerUrl}/version/${urlTemplateParts.id}/debtor/${urlTemplateParts.subId}/requirement`,
			updateRequirement: `${troubledBorrowerUrl}/version/${urlTemplateParts.id}/debtor/${urlTemplateParts.subId}/requirement/${urlTemplateParts.thirdId}`,
			deleteRequirement: `${troubledBorrowerUrl}/version/${urlTemplateParts.id}/debtor/${urlTemplateParts.subId}/requirement/${urlTemplateParts.thirdId}`,
			getRequirementHistoryItems: `${troubledBorrowerUrl}/version/${urlTemplateParts.id}/debtor/${urlTemplateParts.subId}/requirement/${urlTemplateParts.thirdId}/history`,
			deleteRequirementHistory: `${troubledBorrowerUrl}/version/${urlTemplateParts.id}/debtor/${urlTemplateParts.subId}/requirement/${urlTemplateParts.thirdId}/history/${urlTemplateParts.fourthId}`
		},
		items: {
			get: `${troubledBorrowerUrl}/troubled-borrower`
		}
	},
	bod: {
		user: {
			getCurrentUser: `${bodUrl}/me`
		},
		requests: {
			getChangeRequests: `${bodUrl}/changerequests`,
			getRequest: `${bodUrl}/changerequests/${urlTemplateParts.id}`,
			updateRequest: `${bodUrl}/changerequests/${urlTemplateParts.id}`,
			createChangeRequest: `${bodUrl}/changerequests/create`,
			updateChangeRequest: `${bodUrl}/changerequests/update/${urlTemplateParts.id}`,
			unpublishChangeRequest: `${bodUrl}/changerequests/delete/${urlTemplateParts.id}`,
			rollbackChangeRequest: `${bodUrl}/changerequests/rollback/${urlTemplateParts.id}`,
			decline: `${bodUrl}/changerequests/decline/${urlTemplateParts.id}`,
			accept: `${bodUrl}/changerequests/accept/${urlTemplateParts.id}`,
			getPermissions: `${bodUrl}/changerequests/${urlTemplateParts.id}/permissions`,
			getInitiatorUsers: `${bodUrl}/changerequests/users/initiators`
		},
		documents: {
			getDocument: `${bodUrl}/documents/${urlTemplateParts.id}`,
			getDocuments: `${bodUrl}/documents`,
			updateDocument: `${bodUrl}/documents/${urlTemplateParts.id}`,
			getDocumentLink: `${bodUrl}/documents/${urlTemplateParts.id}/share`,
			getPermissions: `${bodUrl}/documents/${urlTemplateParts.id}/permissions`,
			getResponsibleUsers: `${bodUrl}/documents/users/responsibles`
		},
		dictionaries: {
			getUsers: `${bodUrl}/users`,
			getSections: `${bodUrl}/sections`,
			getFinanceSchemes: `${bodUrl}/financeschemes`,
			getDepartments: `${bodUrl}/departments`
		},
		file: {
			createTemper: `${bodUrl}/files`,
			get: `${bodUrl}/files/${urlTemplateParts.id}`
		}
	},
	reports: {
		reports: {
			getReports: `${reportsUrl}/reports/report/${urlTemplateParts.id}/${urlTemplateParts.subId}/${urlTemplateParts.thirdId}/${urlTemplateParts.fourthId}`,
			getReportsFilter: `${reportsUrl}/reports/filter/${urlTemplateParts.id}`,
			uploadReportDocument: `${reportsUrl}/documents/upload/${urlTemplateParts.id}/${urlTemplateParts.subId}/${urlTemplateParts.thirdId}/${urlTemplateParts.fourthId}/${urlTemplateParts.fifthId}`,
			generateReport: `${loanUrl}/${urlTemplateParts.id}`,
			downloadBulk: `${reportsUrl}/reports/download/${urlTemplateParts.id}/${urlTemplateParts.subId}/${urlTemplateParts.thirdId}/${urlTemplateParts.fourthId}`,
			acceptReport: `${reportsUrl}/reports/accept/${urlTemplateParts.id}/${urlTemplateParts.subId}/${urlTemplateParts.thirdId}/${urlTemplateParts.fourthId}`,
			declineReport: `${reportsUrl}/reports/decline/${urlTemplateParts.id}/${urlTemplateParts.subId}/${urlTemplateParts.thirdId}/${urlTemplateParts.fourthId}`,
			sendRequestEmailConfirmation: `${reportsUrl}/reports/requestemailconfirmation/${urlTemplateParts.id}/${urlTemplateParts.subId}/${urlTemplateParts.thirdId}/${urlTemplateParts.fourthId}`,
			addQuery: `${reportsUrl}/reports/report/${urlTemplateParts.id}/${urlTemplateParts.subId}/${urlTemplateParts.thirdId}/${urlTemplateParts.fourthId}/addquery`
		},
		reportsFinal: {
			getFinalReport: `${reportsUrl}/finalreports/report/${urlTemplateParts.id}`,
			uploadFinalReportDocument: `${reportsUrl}/documents/uploadfinal/${urlTemplateParts.id}/${urlTemplateParts.subId}`,
			downloadBulk: `${reportsUrl}/finalreports/download/${urlTemplateParts.id}`,
			acceptReport: `${reportsUrl}/finalreports/accept/${urlTemplateParts.id}`,
			declineReport: `${reportsUrl}/finalreports/decline/${urlTemplateParts.id}`,
			addQueryFinal: `${reportsUrl}/finalreports/report/${urlTemplateParts.id}/addquery`
		}
	},
	bar: {
		bankAccountApplications: {
			downloadBankApplicationTemplate: `${barUrl}/bank-account-application/${urlTemplateParts.id}/document`,
			getBankAccountApplications: `${barUrl}/bank-account-application`,
			getAccountUsage: `${barUrl}/bank-account-application/account-usage`,
			getAccountUsagesById: `${barUrl}/bank-account-application/${urlTemplateParts.id}/account-usage`,
			getBankAccountApplication: `${barUrl}/bank-account-application/${urlTemplateParts.id}`,
			getBankAccountApplicationAccounts: `${barUrl}/bank-account-application/${urlTemplateParts.id}/account`,
			getBankAccountApplicationStatusHistoryItems: `${barUrl}/bank-account-application/${urlTemplateParts.id}/status-history`,
			createBankAccountApplication: `${barUrl}/bank-account-application`,
			updateBankAccountApplication: `${barUrl}/bank-account-application/${urlTemplateParts.id}`,
			generateInformationalLetter: `${barUrl}/bank-account-application/${urlTemplateParts.id}/informational-letter/generate`,
			documents: {
				informationalLetter: {
					getFileInfo: `${barUrl}/bank-account-application/${urlTemplateParts.id}/informational-letter/file`,
					setFile: `${barUrl}/bank-account-application/${urlTemplateParts.id}/informational-letter/file`,
					updateStatus: `${barUrl}/bank-account-application/${urlTemplateParts.id}/informational-letter`,
					updateComment: `${barUrl}/bank-account-application/${urlTemplateParts.id}/informational-letter/comment`
				},
				directWithdrawalAgreement: {
					getFileInfo: `${barUrl}/bank-account-application/${urlTemplateParts.id}/direct-withdrawal-agreement`,
					setFile: `${barUrl}/bank-account-application/${urlTemplateParts.id}/direct-withdrawal-agreement`,
					updateStatus: `${barUrl}/bank-account-application/${urlTemplateParts.id}/direct-withdrawal-agreement`,
					updateComment: `${barUrl}/bank-account-application/${urlTemplateParts.id}/direct-withdrawal-agreement/comment`
				},
				paymentAcceptAgreement: {
					getFileInfo: `${barUrl}/bank-account-application/${urlTemplateParts.id}/payment-accept-agreement`,
					setFile: `${barUrl}/bank-account-application/${urlTemplateParts.id}/payment-accept-agreement`,
					updateStatus: `${barUrl}/bank-account-application/${urlTemplateParts.id}/payment-accept-agreement`,
					updateComment: `${barUrl}/bank-account-application/${urlTemplateParts.id}/payment-accept-agreement/comment`
				},
				accountCorrectionAgreement: {
					getFileInfo: `${barUrl}/bank-account-application/${urlTemplateParts.id}/account-correction-agreement`,
					setFile: `${barUrl}/bank-account-application/${urlTemplateParts.id}/account-correction-agreement`,
					updateStatus: `${barUrl}/bank-account-application/${urlTemplateParts.id}/account-correction-agreement`,
					updateComment: `${barUrl}/bank-account-application/${urlTemplateParts.id}/account-correction-agreement/comment`
				}
			},
			legalDepartment: {
				getLegalDepartmentInfo: `${barUrl}/bank-account-application/${urlTemplateParts.id}/legal-department`,
				getDocuments: `${barUrl}/bank-account-application/${urlTemplateParts.id}/legal-department-request-document`,
				sendDocumentFileInfo: `${barUrl}/bank-account-application/${urlTemplateParts.id}/legal-department-request-document`,
				sendLegalDepartmentRequest: `${barUrl}/bank-account-application/${urlTemplateParts.id}/legal-department/request`,
				setResponsiblePerson: `${barUrl}/bank-account-application/${urlTemplateParts.id}/legal-department/responsible-person`,
				accept: `${barUrl}/bank-account-application/${urlTemplateParts.id}/legal-department/status/accept`,
				decline: `${barUrl}/bank-account-application/${urlTemplateParts.id}/legal-department/status/decline`,
				resetStatuses: `${barUrl}/bank-account-application/${urlTemplateParts.id}/legal-department/status/reset`
			},
			messenger: {
				getMessages: `${barUrl}/bank-account-application/${urlTemplateParts.id}/message`,
				sendMessage: `${barUrl}/bank-account-application/${urlTemplateParts.id}/message`,
				deleteMessage: `${barUrl}/bank-account-application/${urlTemplateParts.id}/message/${urlTemplateParts.subId}`
			},
			deleteBankAccountApplicationDocument: `${barUrl}/bank-account-application/${urlTemplateParts.id}/document/${urlTemplateParts.subId}`
		},
		status: {
			returnToDSP: `${barUrl}/bank-account-application/${urlTemplateParts.id}/status/return-to-dsp`,
			returnToUFKP: `${barUrl}/bank-account-application/${urlTemplateParts.id}/status/return-to-ufkp`,
			toConnect: `${barUrl}/bank-account-application/${urlTemplateParts.id}/status/to-connect`
		},
		bankAccounts: {
			checkBankAccountPayments: `${barUrl}/bank-account/${urlTemplateParts.id}/payment`,
			getBankAccounts: `${barUrl}/bank-account`,
			getBankAccount: `${barUrl}/bank-account/${urlTemplateParts.id}`,
			getBankAccountApplications: `${barUrl}/bank-account/${urlTemplateParts.id}/applications`,
			getBankAccountAgreementTypeHistory: `${barUrl}/bank-account/${urlTemplateParts.id}/project/${urlTemplateParts.subId}/history`,
			createBankAccount: `${barUrl}/bank-account`,
			updateBankAccount: `${barUrl}/bank-account/${urlTemplateParts.id}`
		},
		dictionaries: {
			getMonitoringSystemConnectionTypes: `${barUrl}/dictionary/monitoring-system-connection-type`,
			getSignStatuses: `${barUrl}/dictionary/sign-status`,
			getBankAccountTypes: `${barUrl}/dictionary/bank-account-type`,
			getApplicationBankAccountUsageTypes: `${barUrl}/dictionary/application-bank-account-usage-type`,
			getElectronicDocumentStatuses: `${barUrl}/dictionary/document-electronic-status`,
			getPaperDocumentStatuses: `${barUrl}/dictionary/document-paper-status`,
			getApplicationStatuses: `${barUrl}/dictionary/application-status`,
			getCurrencies: `${barUrl}/dictionary/currency`,
			getBanks: `${barUrl}/dictionary/bank`,
			getBank: `${barUrl}/dictionary/bank/${urlTemplateParts.id}`,
			getBankAndBankBranchByBik: `${barUrl}/dictionary/branch/bik/${urlTemplateParts.id}`,
			getBankBranches: `${barUrl}/dictionary/bank/${urlTemplateParts.id}/branch`,
			getBankBranch: `${barUrl}/dictionary/bank/${urlTemplateParts.id}/branch/${urlTemplateParts.subId}`,
			getSuggestedProjects: `${barUrl}/dictionary/project/suggest`,
			getSuggestedCounterpartiesByQuery: `${barUrl}/dictionary/counterparty/suggest`,
			getSuggestedCounterpartiesById: `${barUrl}/dictionary/counterparty/suggest/${urlTemplateParts.id}`,
			getAgreementInfo: `${barUrl}/dictionary/project/${urlTemplateParts.id}/info`,
			getBankResponsibleUsers: `${barUrl}/dictionary/bank-responsible-user`,
			getStaffEmployees: `${barUrl}/dictionary/employee`,
			getSigners: `${barUrl}/dictionary/signers`,
			getBankSettings: `${barUrl}/dictionary/bank-settings`
		},
		user: {
			getCurrentUser: `${barUrl}/me`
		},
		permissions: {
			get: `${barUrl}/permissions`
		}
	},
	barStorage: {
		file: {
			createTemper: `${barStorageUrl}/file/temp`,
			createTemperSigned: `${barStorageUrl}/file/temp/signed`,
			download: `${barStorageUrl}/file/data/${urlTemplateParts.id}`,
			getMeta: `${barStorageUrl}/file/meta/${urlTemplateParts.id}`
		},
		cryptopro: {
			getSignerCertificates: `${barStorageUrl}/jcp/signature/signer`
		}
	},
	tranches: {
		project: {
			getProjectInfo: `${tranchesUrl}/project/${urlTemplateParts.id}`
		},
		trancheSchedule: {
			getTrancheScheduleHistory: `${tranchesUrl}/tranche-schedule/${urlTemplateParts.id}/history`,
			getTrancheSchedule: `${tranchesUrl}/tranche-schedule/${urlTemplateParts.id}`,
			updateTrancheSchedule: `${tranchesUrl}/tranche-schedule/${urlTemplateParts.id}`,
			updateTrancheScheduleOrderConditions: `${tranchesUrl}/tranche-schedule/${urlTemplateParts.id}/update`,
			sendTrancheForApproval: `${tranchesUrl}/tranche-schedule/${urlTemplateParts.id}/tranches/${urlTemplateParts.subId}/pending`,
			confirmTranche: `${tranchesUrl}/tranche-schedule/${urlTemplateParts.id}/tranches/${urlTemplateParts.subId}/confirm`,
			rejectTranche: `${tranchesUrl}/tranche-schedule/${urlTemplateParts.id}/tranches/${urlTemplateParts.subId}/reject`,
			acceptTranche: `${tranchesUrl}/tranche-schedule/${urlTemplateParts.id}/tranches/${urlTemplateParts.subId}/accept`,
			unacceptTranche: `${tranchesUrl}/tranche-schedule/${urlTemplateParts.id}/tranches/${urlTemplateParts.subId}/unaccept`,
			orderTranchePaymentApplication: `${tranchesUrl}/tranche-schedule/${urlTemplateParts.id}/order/${urlTemplateParts.subId}`,
			generateInformationalLetter: `${tranchesUrl}/tranche-schedule/${urlTemplateParts.id}/infodoc`,
			addTrancheDocument: `${tranchesUrl}/tranche-schedule/${urlTemplateParts.id}/tranches/${urlTemplateParts.subId}/documents/${urlTemplateParts.thirdId}`,
			getTrancheDocument: `${tranchesUrl}/tranche-schedule/${urlTemplateParts.id}/tranches/${urlTemplateParts.subId}/documents/${urlTemplateParts.thirdId}`,
			deleteTrancheDocument: `${tranchesUrl}/tranche-schedule/${urlTemplateParts.id}/tranches/${urlTemplateParts.subId}/documents/${urlTemplateParts.thirdId}`,
			addTrancheAgreement: `${tranchesUrl}/tranche-schedule/${urlTemplateParts.id}/tranches/${urlTemplateParts.subId}/counterpartyAgreements/${urlTemplateParts.thirdId}`,
			deleteTrancheAgreement: `${tranchesUrl}/tranche-schedule/${urlTemplateParts.id}/tranches/${urlTemplateParts.subId}/counterpartyAgreements/${urlTemplateParts.thirdId}`,
			connectTrancheScheduleMessages: `${tranchesUrl}/tranche-schedule/${urlTemplateParts.id}/messages/connect`
		},
		permissions: {
			get: `${tranchesUrl}/permissions`
		}
	},
	rex: {
		advice: {
			resultExpertises: `${rexUrl}/reports/advice/result_expertises/${urlTemplateParts.id}`
		}
	},
	kpi: {
		permissions: {
			get: `${kpiUrl}/permissions`
		},
		user: {
			getCurrentUser: `${kpiUrl}/me`
		},
		kpi: {
			getPermissions: `${kpiUrl}/kpi/permissions/${urlTemplateParts.id}`,
			createKpi: `${kpiUrl}/kpi`,
			updateKpi: `${kpiUrl}/kpi/${urlTemplateParts.id}`,
			deleteKpi: `${kpiUrl}/kpi/${urlTemplateParts.id}`,
			updateStaffWeights: `${kpiUrl}/kpi/${urlTemplateParts.id}/update-staff-weights`,
			getKpi: `${kpiUrl}/kpi/${urlTemplateParts.id}`,
			getKpisByYear: `${kpiUrl}/kpi/registry/${urlTemplateParts.id}`,
			getPeriods: `${kpiUrl}/kpi/periods`
		},
		result: {
			getReportingByYear: `${kpiUrl}/result/report/${urlTemplateParts.id}`,
			getReportingByQuarter: `${kpiUrl}/result/report/${urlTemplateParts.id}/${urlTemplateParts.subId}`,
			getEmployeeReportingByQuarter: `${kpiUrl ||
			hrKpiUrl}/result/report/${urlTemplateParts.id}/${urlTemplateParts.subId}/staff/${urlTemplateParts.thirdId}`,
			setStaffCriteriaResult: `${kpiUrl}/result/staff/${urlTemplateParts.id}`,
			getPermissionsByYear: `${kpiUrl}/result/report/${urlTemplateParts.id}/permissions`,
			getPermissionsByQuarter: `${kpiUrl}/result/report/${urlTemplateParts.id}/${urlTemplateParts.subId}/permissions`,
			signScoreCardByYear: `${kpiUrl}/result/staff/${urlTemplateParts.id}/${urlTemplateParts.subId}/sign`,
			signScoreCardByQuarter: `${kpiUrl}/result/staff/${urlTemplateParts.id}/${urlTemplateParts.subId}/${urlTemplateParts.thirdId}/sign`,
			archive: `${kpiUrl}/result/archive`
		},
		staff: {
			setStaffPecularity: `${kpiUrl}/staff/pecularity/${urlTemplateParts.id}`,
			setStaffLaborRate: `${kpiUrl}/staff/${urlTemplateParts.id}/labor-rate`,
			getStaffList: `${kpiUrl}/staff/list`,
			getDepartments: `${kpiUrl}/staff/departments`
		},
		reportGenerator: {
			downloadRegistry: `${kpiUrl}/reportgenerator/registry/${urlTemplateParts.id}`
		},
		delegacy: {
			getDelegacy: `${kpiUrl}/delegacy/registry`,
			createDelegacy: `${kpiUrl}/delegacy/registry`,
			deleteDelegacy: `${kpiUrl}/delegacy/${urlTemplateParts.id}`,
			getAppointers: `${kpiUrl}/delegacy/registry/appointer`,
			getAttorneys: `${kpiUrl}/delegacy/registry/attorney/${urlTemplateParts.id}`
		},
		storage: {
			file: {
				createTemper: `${kpiUrl}/filestorage/file/temp`,
				createTemperSigned: `${kpiUrl}/filestorage/file/temp/signed`,
				tempDownload: `${kpiUrl}/filestorage/file/temp/data/${urlTemplateParts.id}`,
				getTemperMeta: `${kpiUrl}/filestorage/file/temp/meta/${urlTemplateParts.id}`,
				download: `${kpiUrl}/filestorage/file/data/${urlTemplateParts.id}`,
				getMeta: `${kpiUrl}/filestorage/file/meta/${urlTemplateParts.id}`
			},
			cryptopro: {
				getSignerCertificates: `${kpiUrl}/filestorage/jcp/signature/signer`
			}
		}
	},
	hr: {
		employee: {
			getEmployees: `${hrUrl}/employee`,
			getProfile: `${hrUrl}/employee/${urlTemplateParts.id}/profile`,
			getStandard: `${hrUrl}/employee/${urlTemplateParts.id}/standard`,
			getDiscipline: `${hrUrl}/employee/${urlTemplateParts.id}/discipline`,
			getVacation: `${hrUrl}/employee/${urlTemplateParts.id}/vacation`,
			getColleagues: `${hrUrl}/employee/${urlTemplateParts.id}/colleagues`,
			getSubordinates: `${hrUrl}/employee/${urlTemplateParts.id}/subordinates`,
			getPermissions: `${hrUrl}/employee/${urlTemplateParts.id}/permissions`,
			getBitrixPromises: `${hrUrl}/employee/${urlTemplateParts.id}/tasks/portal/summary`
		},
		user: {
			getCurrentUser: `${hrUrl}/vacationsplan/me`
		},
		department: {
			getDepartments: `${hrUrl}/department`
		},
		administration: {
			getLastYear: `${hrUrl}/administration/year/last`,
			getYear: `${hrUrl}/administration/year/${urlTemplateParts.id}`,
			getYears: `${hrUrl}/administration/year`,
			updateYear: `${hrUrl}/administration/year/${urlTemplateParts.id}`,
			importYearEmployees: `${hrUrl}/administration/year/${urlTemplateParts.id}/employees`,
			getYearEmployees: `${hrUrl}/administration/year/${urlTemplateParts.id}/employees`,
			addYearEmployee: `${hrUrl}/administration/year/${urlTemplateParts.id}/employee/${urlTemplateParts.subId}`,
			deleteYearEmployee: `${hrUrl}/administration/year/${urlTemplateParts.id}/employee/${urlTemplateParts.subId}`,
			updateEmployeeVacationDays: `${hrUrl}/administration/year/${urlTemplateParts.id}/employee/${urlTemplateParts.subId}`
		},
		plannedVacation: {
			getYears: `${hrUrl}/vacationsplan/years`,
			getVacationPlanDepartments: `${hrUrl}/vacationsplan/departments`,
			getVacationPlanEmployees: `${hrUrl}/vacationsplan/employees`,
			getLatestVacationPlans: `${hrUrl}/vacationsplan/${urlTemplateParts.id}/latest`,
			getVacationPlans: `${hrUrl}/vacationsplan/${urlTemplateParts.id}/employees`,
			createVacationPlan: `${hrUrl}/vacationsplan/${urlTemplateParts.id}/employee/${urlTemplateParts.subId}`,
			createAndApproveVacationPlan: `${hrUrl}/vacationsplan/${urlTemplateParts.id}/employee/${urlTemplateParts.subId}/createreviewapprove`,
			sendVacationPlanToReview: `${hrUrl}/vacationsplan/${urlTemplateParts.id}/${urlTemplateParts.subId}/review/${urlTemplateParts.thirdId}`,
			approveVacationPlan: `${hrUrl}/vacationsplan/${urlTemplateParts.id}/${urlTemplateParts.subId}/approve/${urlTemplateParts.thirdId}`,
			approveVacationPlans: `${hrUrl}/vacationsplan/${urlTemplateParts.id}/massapprove`,
			rejectVacationPlan: `${hrUrl}/vacationsplan/${urlTemplateParts.id}/${urlTemplateParts.subId}/decline/${urlTemplateParts.thirdId}`,
			withdrawVacationPlan: `${hrUrl}/vacationsplan/${urlTemplateParts.id}/${urlTemplateParts.subId}/withdraw/${urlTemplateParts.thirdId}`,
			deleteDraft: `${hrUrl}/vacationsplan/${urlTemplateParts.id}/${urlTemplateParts.subId}/${urlTemplateParts.thirdId}`,
			exportPlans: `${hrUrl}/vacationsplan/export/${urlTemplateParts.id}/t8`,
			getPermissions: `${hrUrl}/vacationsplan/permissions`
		},
		vacationApplication: {
			signDocument: `${hrUrl}/document/document/${urlTemplateParts.id}/sign`,
			getApplications: `${hrUrl}/vacationapplication/applications`,
			getApplication: `${hrUrl}/vacationapplication/applications/${urlTemplateParts.id}`,
			createOwnExpenseVacationApplication: `${hrUrl}/vacationapplication/applications/ownexpense`,
			createPlannedVacationApplication: `${hrUrl}/vacationapplication/applications/planned`,
			createStudyVacationApplication: `${hrUrl}/vacationapplication/applications/study`,
			createDonorVacationApplication: `${hrUrl}/vacationapplication/applications/donor`,
			createClinicalExaminationVacationApplication: `${hrUrl}/vacationapplication/applications/clinicalexamination`,
			createOtherVacationApplication: `${hrUrl}/vacationapplication/applications/other`,
			sendToReview: `${hrUrl}/vacationapplication/applications/${urlTemplateParts.id}/transit/inreview`,
			withdraw: `${hrUrl}/vacationapplication/applications/${urlTemplateParts.id}/transit/withdraw`,
			approve: `${hrUrl}/vacationapplication/applications/${urlTemplateParts.id}/${urlTemplateParts.subId}/transit/approve`,
			decline: `${hrUrl}/vacationapplication/applications/${urlTemplateParts.id}/${urlTemplateParts.subId}/transit/decline`,
			addApprover: `${hrUrl}/vacationapplication/applications/${urlTemplateParts.id}/approvals/${urlTemplateParts.subId}`,
			removeApprover: `${hrUrl}/vacationapplication/applications/${urlTemplateParts.id}/approvals/${urlTemplateParts.subId}`,
			createOrder: `${hrUrl}/vacationapplication/applications/${urlTemplateParts.id}/documents/order`,
			downloadVacationArchive: `${hrUrl}/vacationapplication/archive`
		},
		substitute: {
			getSubstitutes: `${hrUrl}/vacationapplication/substitute/${urlTemplateParts.id}/employees`
		},
		vacation: {
			getAvailableVacations: `${hrUrl}/vacationapplication/${urlTemplateParts.id}/planned`
		},
		storage: {
			file: {
				createTemper: `${hrUrl}/filestorage/file/temp`,
				createTemperSigned: `${hrUrl}/filestorage/file/temp/signed`,
				tempDownload: `${hrUrl}/filestorage/file/temp/data/${urlTemplateParts.id}`,
				getTemperMeta: `${hrUrl}/filestorage/file/temp/meta/${urlTemplateParts.id}`,
				download: `${hrUrl}/filestorage/file/data/${urlTemplateParts.id}`,
				getMeta: `${hrUrl}/filestorage/file/meta/${urlTemplateParts.id}`
			},
			cryptopro: {
				getSignerCertificates: `${hrUrl}/filestorage/jcp/signature/signer`
			}
		},
		task: {
			getTasks: `${hrUrl}/task/tasks`,
			approveTask: `${hrUrl}/task/tasks/approval/${urlTemplateParts.id}/transit/approve`,
			declineTask: `${hrUrl}/task/tasks/approval/transit/decline`,
			signTask: `${hrUrl}/task/tasks/signing/transit/sign`,
			declineSigningTask: `${hrUrl}/task/tasks/signing/transit/decline`,
			markTaskInformationAsDone: `${hrUrl}/task/tasks/information/transit/done`,
			getTaskStats: `${hrUrl}/task/tasksstats`
		},
		document: {
			sign: `${hrUrl}/document/${urlTemplateParts.id}/sign`
		},
		personnelDocument: {
			getPartitions: `${hrUrl}/personneldocument/partitions`,
			createPartition: `${hrUrl}/personneldocument/partition`,
			updatePartition: `${hrUrl}/personneldocument/partition/${urlTemplateParts.id}`,
			deletePartition: `${hrUrl}/personneldocument/partition/${urlTemplateParts.id}`,
			createDocument: `${hrUrl}/personneldocument/document`,
			updateDocument: `${hrUrl}/personneldocument/document/${urlTemplateParts.id}`,
			deleteDocument: `${hrUrl}/personneldocument/document/${urlTemplateParts.id}`
		},
		approval: {
			getApproval: `${hrUrl}/approval/${urlTemplateParts.id}`
		}
	}
};

export default urls;

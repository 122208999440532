import baseMixinTypes from "@/store/shared/base/types";
import stateManipulationMixinTypes from "@/store/shared/stateManipulation/types";
import snapshotMixinTypes from "@/store/shared/snapshot/types";
import { listingActionTypes, listingGetterTypes, listingMutationTypes } from "@/store/shared/listing/types";
import { pagingActionTypes, pagingMutationTypes } from "@/store/shared/paging/types";
import { sortingActionTypes, sortingMutationTypes } from "@/store/shared/sorting/types";
import { searchMixinTypes } from "@/store/shared/search/types";

export const namespace = "tranches-tranche-application";

export const getterTypes = {
	...snapshotMixinTypes.getterTypes,
	...listingGetterTypes,
	isDraftTrancheScheduleStatus: "isDraftTrancheScheduleStatus",
	isAcceptedTrancheScheduleStatus: "isAcceptedTrancheScheduleStatus",
	isPendingTrancheScheduleStatus: "isPendingTrancheScheduleStatus",
	lkProjectLink: "lkProjectLink",
	currentDialogTranche: "currentDialogTranche",
	closestConfirmedTranche: "closestConfirmedTranche",
	confirmableTranche: "confirmableTranche",
	canEditConfirmedTranche: "canEditConfirmedTranche"
};

export const actionTypes = {
	...stateManipulationMixinTypes.actionTypes,
	...baseMixinTypes.actionTypes,
	...snapshotMixinTypes.actionTypes,
	...pagingActionTypes,
	...sortingActionTypes,
	...listingActionTypes,
	...searchMixinTypes.actionTypes,
	getProjectInfo: "getProjectInfo",
	sendTrancheForApproval: "sendTrancheScheduleForApprovalByManager",
	addEditableItemTranche: "addEditableItemTranche",
	deleteEditableItemTranche: "deleteEditableItemTranche",
	createOrderTranchePaymentApplication: "createOrderTranchePaymentApplication",
	acceptTranche: "acceptTrancheSchedule",
	confirmTranche: "withdrawTrancheSchedule",
	rejectTranche: "rejectTrancheSchedule",
	updateTrancheSchedule: "updateTrancheSchedule",
	updateTrancheScheduleOrderConditions: "updateTrancheScheduleOrderConditions",
	fetchHistory: "fetchHistory",
	findClosestTranches: "findClosestTranches",
	addTrancheDocument: "addTrancheDocument",
	deleteTrancheDocument: "deleteTrancheDocument",
	updateTrancheAgreements: "updateTrancheAgreements",
	unacceptTranche: "unacceptTranche"
};

export const mutationTypes = {
	...stateManipulationMixinTypes.mutationTypes,
	...baseMixinTypes.mutationTypes,
	...snapshotMixinTypes.mutationTypes,
	...sortingMutationTypes,
	...pagingMutationTypes,
	...listingMutationTypes,
	...searchMixinTypes.mutationTypes,
	SET_IS_TRANCHE_SCHEDULE_SAVING: "SET_IS_TRANCHE_SCHEDULE_SAVING",
	SET_IS_TRANCHE_SCHEDULE_ORDER_CONDITIONS_SAVING: "SET_IS_TRANCHE_SCHEDULE_ORDER_CONDITIONS_SAVING",
	SET_IS_TRANCHE_SCHEDULE_FORM_VALID: "SET_IS_TRANCHE_SCHEDULE_FORM_VALID",
	SET_IS_TRANCHE_SCHEDULE_FOR_APPROVAL_BY_MANAGER_SENDING: "SET_IS_TRANCHE_SCHEDULE_FOR_APPROVAL_BY_MANAGER_SENDING",
	SET_IS_ORDER_TRANCHE_PAYMENT_APPLICATION_CREATING: "SET_IS_ORDER_TRANCHE_PAYMENT_APPLICATION_CREATING",
	SET_TRANCHE_SCHEDULE_TRANCHES_TRANCHE_ORDER_DATE: "SET_TRANCHE_SCHEDULE_TRANCHES_TRANCHE_ORDER_DATE",
	SET_TRANCHE_ORDER_FILE: "SET_TRANCHE_ORDER_FILE",
	SET_TRANCHE_ORDER_FILENAME: "SET_TRANCHE_ORDER_FILENAME",
	SET_TRANCHE_SCHEDULE_TRANCHES_TRANCHE_ORDER_CREATING: "SET_TRANCHE_SCHEDULE_TRANCHES_TRANCHE_ORDER_CREATING",
	SET_APPLICATION_NUMBER: "SET_APPLICATION_NUMBER",
	SET_IS_PROJECT_INFO_FETCHING: "SET_IS_PROJECT_INFO_FETCHING",
	SET_PROJECT_INFO: "SET_PROJECT_INFO",
	SET_TRANCHE_SCHEDULE: "SET_TRANCHE_SCHEDULE",
	SET_TRANCHE_STATUS: "SET_TRANCHE_SCHEDULE_STATUS",
	SET_TRANCHE_SCHEDULE_TRANCHES: "SET_TRANCHE_SCHEDULE_TRANCHES",
	SET_IS_TRANCHE_ACCEPTING: "SET_IS_TRANCHE_ACCEPTING",
	SET_IS_TRANCHE_REJECTING: "SET_IS_TRANCHE_REJECTING",
	SET_IS_TRANCHE_CONFIRMING: "SET_IS_TRANCHE_SCHEDULE_WITHDRAWING",
	SET_MODE: "SET_MODE",
	RESET_EDITABLE_ITEMS: "RESET_EDITABLE_ITEMS",
	SET_EDITABLE_ITEMS: "SET_EDITABLE_ITEMS",
	SET_TRANCHE_SCHEDULE_SUM: "SET_TRANCHE_SCHEDULE_SUM",
	SET_IS_TRANCHE_SCHEDULE_UNSAVED_CHANGES_DIALOG_ENABLED: "SET_IS_TRANCHE_SCHEDULE_UNSAVED_CHANGES_DIALOG_ENABLED",
	SET_HISTORY: "SET_HISTORY",
	SET_CURRENT_DIALOG_TRANCHE_NUMBER: "SET_CURRENT_DIALOG_TRANCHE_NUMBER",
	SET_CLOSEST_CONFIIRMED_TRANCHE_NUMBER: "SET_CLOSEST_CONFIIRMED_TRANCHE_NUMBER",
	SET_CLOSEST_DRAFT_TRANCHE_NUMBER: "SET_CLOSEST_DRAFT_TRANCHE_NUMBER",
	SET_IS_DOCUMENT_UPLOADING: "SET_IS_DOCUMENT_UPLOADING",
	ADD_TRANCHE_DOCUMENT: "ADD_TRANCHE_DOCUMENT",
	REMOVE_TRANCHE_DOCUMENT: "REMOVE_TRANCHE_DOCUMENT",
	ADD_DELETING_DOCUMENT_IDS: "ADD_DELETING_DOCUMENT_IDS",
	REMOVE_DELETING_DOCUMENT_IDS: "REMOVE_DELETING_DOCUMENT_IDS",
	SET_IS_TRANCHE_AGREEMENTS_SAVING: "SET_IS_TRANCHE_AGREEMENTS_SAVING",
	SET_TRANCHE_AGREEMENT_IDS: "SET_TRANCHE_AGREEMENT_IDS",
	SET_IS_UNACCEPTING: "SET_IS_UNACCEPTING"
};

const tranchesTrancheApplicationTypes = {
	namespace, actionTypes, mutationTypes, getterTypes
};

export default tranchesTrancheApplicationTypes;

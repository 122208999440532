import baseMixinTypes, { baseActionTypes } from "@/store/shared/base/types";
import routeTypes from "@/store/shared/route/types";
import stateManipulationMixinTypes from "@/store/shared/stateManipulation/types";
import { listingGetterTypes, listingActionTypes, listingMutationTypes } from "@/store/shared/listing/types";
import { pagingActionTypes, pagingMutationTypes } from "@/store/shared/paging/types";
import { sortingActionTypes, sortingMutationTypes } from "@/store/shared/sorting/types";
import { searchMixinTypes } from "@/store/shared/search/types";
import formTypes from "@/store/shared/form/types";
import snapshotMixinTypes from "@/store/shared/snapshot/types";

export const namespace = "vacation-plan-employees";

export const getterTypes = {
	...listingGetterTypes,
	...formTypes.getterTypes,
	...snapshotMixinTypes.getterTypes,
	yearValues: "yearValues"
};

export const actionTypes = {
	...stateManipulationMixinTypes.actionTypes,
	...baseActionTypes,
	...pagingActionTypes,
	...sortingActionTypes,
	...listingActionTypes,
	...searchMixinTypes.actionTypes,
	...routeTypes.actionTypes,
	...formTypes.actionTypes,
	...snapshotMixinTypes.actionTypes,
	fetchValues: "fetchValues",
	fetchHiredDate: "fetchHiredDate",
	fetchYears: "fetchYears",
	fetchDepartments: "fetchDepartments",
	fetchEmployees: "fetchEmployees",
	importEmployees: "importEmployees",
	importEmployee: "importEmployee",
	deleteEmployee: "deleteEmployee",
	updateEmployeeVacationDays: "updateEmployeeVacationDays"
};

export const mutationTypes = {
	...baseMixinTypes.mutationTypes,
	...stateManipulationMixinTypes.mutationTypes,
	...sortingMutationTypes,
	...pagingMutationTypes,
	...listingMutationTypes,
	...searchMixinTypes.mutationTypes,
	...routeTypes.mutationTypes,
	...formTypes.mutationTypes,
	...snapshotMixinTypes.mutationTypes,
	SET_FILTER_YEAR: "SET_FILTER_YEAR",
	SET_FILTER_DEPARTMENT_IDS: "SET_FILTER_DEPARTMENT_IDS",
	SET_FILTER_EMPLOYEE_IDS: "SET_FILTER_EMPLOYEE_IDS",
	RESET_FILTER: "RESET_FILTER",
	SET_IS_YEARS_LOADING: "SET_IS_YEARS_LOADING",
	SET_IS_DEPARTMENTS_LOADING: "SET_IS_DEPARTMENTS_LOADING",
	SET_IS_EMPLOYEES_LOADING: "SET_IS_EMPLOYEES_LOADING",
	SET_YEARS: "SET_YEARS",
	SET_DEPARTMENTS: "SET_DEPARTMENTS",
	SET_EMPLOYEES: "SET_EMPLOYEES",
	SET_IMPORT_EMPLOYEES_RESPONSE: "SET_IMPORT_EMPLOYEES_RESPONSE",
	RESET_IMPORT_EMPLOYEES_RESPONSE: "RESET_IMPORT_EMPLOYEES_RESPONSE",
	RESET_IMPORT_EMPLOYEES_REQUEST: "RESET_IMPORT_EMPLOYEES_REQUEST",
	RESET_UPDATE_VACATION_DAYS_REQUEST: "RESET_UPDATE_VACATION_DAYS_REQUEST",
	SET_UPDATE_VACATION_DAYS_REQUEST_VACATION_DAYS: "SET_UPDATE_VACATION_DAYS_REQUEST_VACATION_DAYS",
	SET_UPDATE_VACATION_DAYS_REQUEST_COMMENT: "SET_UPDATE_VACATION_DAYS_REQUEST_COMMENT",
	SET_IMPORT_EMPLOYEES_REQUEST_HIRE_DATE_FROM: "SET_IMPORT_EMPLOYEES_REQUEST_HIRE_DATE_FROM",
	SET_IMPORT_EMPLOYEES_REQUEST_HIRE_DATE_TO: "SET_IMPORT_EMPLOYEES_REQUEST_HIRE_DATE_TO"
};

const vacationPlanEmployeesTypes = {
	namespace, actionTypes, mutationTypes, getterTypes
};

export default vacationPlanEmployeesTypes;

// @ts-ignore
import masker from "vue-the-mask/src/masker";
// @ts-ignore
import tokens from "vue-the-mask/src/tokens";
import { isNumber, isNil } from "lodash";
import { customTokens } from "@/plugins/vueTheMask";

const maskTokens = { ...tokens, ...customTokens };
import ApiLegalEntityCounterparty from "@/api/types/counterparty/apiLegalEntityCounterparty";
import ApiLegalPersonCounterparty from "@/api/types/counterparty/apiLegalPersonCounterparty";
import { i18n } from "@/plugins";
import ApiForeignOrganizationCounterparty from "@/api/types/counterparty/apiForeignOrganizationCounterparty";
import ApiPhysicalPersonCounterparty from "@/api/types/counterparty/apiPhysicalPersonCounterparty";
import { CounterpartyType } from "@/store/modules/counterpartyEmployee/types/counterpartyEmployeeState";
import { KpiQuarterEnum } from "@/types/kpi/KpiQuarterEnum";

export const removeSpaces = (row: string) => row ? row.replace(/\s+/g, "") : "";

export const capitalizeFirstLetter = (row: string) => row && row[0].toUpperCase() + row.slice(1);

export const formatFullName = ({ firstName, lastName, middleName }: { firstName: string, lastName: string, middleName: string }) => {
	return [lastName, firstName, middleName].filter(x => !!x).join(" ");
};

export const formatFullNameWithInitials = ({ firstName, lastName, middleName }: {
	firstName: string,
	lastName: string,
	middleName: string
}) => {
	return [firstName, lastName, middleName].every(x => !x) ?
		"" :
		`${lastName} ${firstName ? firstName.slice(0, 1) + "." : ""}${middleName ? middleName.slice(0, 1) + "." : ""}`;
};

export const formatInitials = ({ firstName, middleName }: {
	firstName: string,
	middleName: string
}) => {
	return [firstName, middleName].every(x => !x) ?
		"" :
		`${firstName ? firstName.slice(0, 1) + "." : ""}${middleName ? middleName.slice(0, 1) + "." : ""}`;
};

export const mask = (value: string, mask: string) => {
	return value && masker(value, mask, true, maskTokens);
};

export const unmask = (value: string, mask: string) => {
	return value && masker(value, mask, false, maskTokens);
};

export const formatCurrency = (value: number, fractionDigits = 2) => {
	if(!value && value !== 0)
		return value;
	
	return value.toFixed(fractionDigits).replace(".", ",").toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

export const convertNumberToIsoString = (value: number) => {
	return isNumber(value) ? new Date(value).toISOString() : value;
};

export const formatNullableTableValue = (value: any, alt: string = "--") => {
	return isNil(value) || value === "" ? alt : value;
};

export const formatCounterpartyTitle = (counterparty: CounterpartyType) => {
	if(counterparty instanceof ApiLegalEntityCounterparty) {
		return counterparty.shortName;
	} else if(counterparty instanceof ApiLegalPersonCounterparty) {
		return `${i18n.t("common.legalPerson")} "${counterparty.fullName}"`;
	} else if(counterparty instanceof ApiForeignOrganizationCounterparty) {
		return counterparty.longName;
	} else if(counterparty instanceof ApiPhysicalPersonCounterparty) {
		return formatFullName({ ...counterparty });
	} else
		return "";
};

export const formatKpiQuarterValueByText = (value: number | null) => {
	switch (value) {
		case 1:
			return i18n.t(`aliases.kpiQuarter.${KpiQuarterEnum.I}`);
		case 2:
			return i18n.t(`aliases.kpiQuarter.${KpiQuarterEnum.II}`);
		case 3:
			return i18n.t(`aliases.kpiQuarter.${KpiQuarterEnum.III}`);
		case 4:
			return i18n.t(`aliases.kpiQuarter.${KpiQuarterEnum.IV}`);
		case null:
			return i18n.t(`aliases.kpiQuarter.${KpiQuarterEnum.YEAR}`);
		default:
			break;
	}
};